import Layout from "../relaunch/components/layouts/layout/layout"
import { Pricing } from "../relaunch/components/layouts/pricing/pricing"

const seo = {
  title: "Pricing - Fair pricing that scales with your business | Ory",
  description:
    "Ory's fair pricing scales with your business. Add any auth flow at any scale with our production-ready plans. Pricing is based on usage, not Monthly Active Users.",
}

const PricingPage = () => {
  return (
    <Layout bgNext>
      <Pricing />
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>{seo.title}</title>
    <meta name="description" content={seo.description} />
    <meta property="og:title" content={seo.title} />
    <meta property="og:description" content={seo.description} />
    <meta property="og:type" content="website" />
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:author" content="Ory" />
    <meta property="twitter:title" content={seo.title} />
    <meta property="twitter:description" content={seo.description} />
  </>
)

export default PricingPage
